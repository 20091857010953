import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
  },
  cta: {
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  },
  ctaText: {
    fontWeight: 900,
    background: "rgba(0,0,0,0.25)"
  },
  spacer: {
    [theme.breakpoints.down("sm")]: {
      height: "5vh",
    },
    [theme.breakpoints.up("sm")]: {
      height: "20vh",
    },
  },
}));

export const HomeCTA: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid container justify="center" className={classes.root}>
      <Grid item xs={10}>
        <div className={classes.spacer} />
        <Typography
          variant="h1"
          className={classes.ctaText}
          align="center"
          gutterBottom
        >
          Venture Studio
        </Typography>
        <Typography
          variant="h4"
          className={classes.ctaText}
          align="center"
          gutterBottom
        >
          We are a venture studio of industry proven technologists who build and launch companies and products to solve hard problems using all available technology.
        </Typography>
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
          }}
        >
          {/* <Link href="/projects">
            <Button
              className={classes.cta}
              variant="contained"
              color="primary"
              size="large"
            >
              See our projects
            </Button>
          </Link>
          <span style={{ width: 50, padding: 20 }}>- or -</span>
          <Link href="/contact">
            <Button
              className={classes.cta}
              variant="contained"
              color="primary"
              size="large"
            >
              Find Out More
            </Button>
          </Link> */}
        </div>
      </Grid>
      {/* <Grid item xs={10}>
        <Customers />
      </Grid> */}
    </Grid>
  );
};
