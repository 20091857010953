import React from "react";

import { PageProps } from "gatsby";
import Layout from "@/components/layout";
import Image from "@/components/image";
import SEO from "@/components/seo";
import Logo from "@/components/logo";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";

import StarIcon from "@material-ui/icons/Star";
import InfoIcon from "@material-ui/icons/Info";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Done from "@material-ui/icons/Done";
import Link from "@material-ui/core/Link";
import { url } from "inspector";

import BG from "../assets/images/Stars.svg";
import SpaceMan from "../assets/images/Astronaut.svg";
import Typography from "@material-ui/core/Typography";
import { SignupForm } from "@/components/SignupForm";
import { Button } from "@material-ui/core";
import { HomeCTA } from "../components/homeCta";
import { Footer } from "@/components/footer";
import Header from "@/components/header";
import { LogoVerticalComponent } from "../components/logo";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },

  bg: {
    backgroundImage: `url(${BG})`,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100vh",
    width: "100vw",
    paddingTop: 50,
  },
  SpaceMan: {
    backgroundImage: `url(${SpaceMan})`,
    backgroundPosition: "right bottom",
    backgroundRepeat: "no-repeat",
    backgroundSize: "300px",
    height: "70vh",
    [theme.breakpoints.down("sm")]: {
      height: "80vh",
      backgroundSize: 100,
    },
  },
}));

const IndexPage: React.FC<PageProps> = () => {
  const classes = useStyles();

  return (
    <div className={classes.bg}>
      <div className={classes.SpaceMan}>
        <Layout>
          {/* <Header /> */}
          <SEO title="We Build and Launch Tomorrows Technology." />
          <LogoVerticalComponent variant="vertical" />
          <HomeCTA />
          <Footer />
        </Layout>
      </div>
    </div>
  );
};

export default IndexPage;
